<template>
    <div class="status-icon" :class="[statusClass, positionClass]">
        <SvgIcon :name="iconName" class="icon status-icon__icon" :style="{width: iconWidth +'px', height: iconHeight +'px'}"/>
    </div>
</template>

<script>
export default {
    name: "UnitsList_unit_status_icon",
    props: {
        'iconName': {
            type: String,
            default: () => {return 'empty'}
        },
        iconWidth:{
          type: Number,
          default: () => { return 8 }
        },
        iconHeight:{
            type: Number,
            default: () => { return 6 }
        },
        'isVideo': Boolean,
        'status': {
            type: String,
            default: () => { return 'online' }
        },
        'position':{
            type: String,
            default: () => { return 'tl' }
        }
    },
    data() {
        return {
            statusClasses: {
                online: 'status-icon__ok',
                offline: 'status-icon__error',
                standby: 'status-icon__warning',
            }
        }
    },
    computed: {
        statusClass(){
            return this.statusClasses[this.status] || 'status-icon__'+this.status
        },
        positionClass(){
            return 'status-icon__position-' + this.position
        }
    },
}
</script>

<style scoped>

</style>